import clsx from 'clsx';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import logo from '../../../../static/logo.svg';
import { Layout, SEO } from '../../../components';
import './index.scss';

const YSOSurveyForParents = (
  {
    // data: {  }
  }
) => {
  const [clickId] = useQueryParam('clickid', StringParam);
  useEffect(() => {
    requestAnimationFrame(() => window.scrollTo(0, 0));
  }, []);

  return (
    <Layout bodyClass="survey-for-parents text-left text-black" lpMode>
      <SEO title="Survey for Parents" url="/lp/survey-for-parents/" />

      <section className="h-screen p-4">
        <div className={clsx('container max-w-xl m-auto flex flex-col h-full')}>
          <img alt="YOU Are Safe Online logo" className="w-48" src={logo} />
          <div className="flex flex-col items-center mt-12 mb-12">
            <svg
              style={{ width: '8rem' }}
              className="w-32 h-32"
              version="1.1"
              viewBox="200 200 450 300"
            >
              <g>
                <rect
                  x="387.391"
                  y="225.778"
                  fill="#000000"
                  width="72.026"
                  height="5.825"
                />
                <rect
                  x="387.391"
                  y="251.017"
                  fill="#000000"
                  width="116.62"
                  height="5.833"
                />
                <rect
                  x="387.391"
                  y="305.843"
                  fill="#000000"
                  width="132.822"
                  height="5.827"
                />
                <rect
                  x="387.391"
                  y="331.081"
                  fill="#000000"
                  width="72.026"
                  height="5.818"
                />
                <path
                  fill="#000000"
                  d="M370.161,213.804h-50.697v50.693h50.697V213.804z M366.352,260.688h-43.078v-43.074h43.078V260.688z"
                />
                <path
                  fill="#000000"
                  d="M319.464,344.563h50.697V293.87h-50.697V344.563z M323.274,297.68h43.078v43.07h-43.078V297.68z"
                />
                <path
                  fill="#000000"
                  d="M370.161,424.622v-38.607l23.593-23.594l-9.042-9.04l-20.546,20.545H322.94l-2.756-2.754l-9.036,9.039   l8.316,8.317v36.094H370.161z M366.352,420.808h-43.078v-28.472l20.28,20.284l22.798-22.798V420.808z M360.357,377.739   l-16.803,16.803l-16.804-16.803H360.357z"
                />
                <rect
                  x="387.391"
                  y="385.893"
                  fill="#000000"
                  width="132.822"
                  height="5.841"
                />
                <rect
                  x="387.391"
                  y="411.139"
                  fill="#000000"
                  width="126.687"
                  height="5.826"
                />
                <path
                  fill="#000000"
                  d="M558.785,124.912h-58.859v28.203h51.615v339.735H290.442V153.115h50.443v-28.203h-57.812   c-15.208,0-16.862,1.658-16.862,16.874v357.988c0,15.216,1.653,16.866,16.862,16.866h275.712c15.207,0,16.865-2.713,16.865-17.921   V141.786C575.651,126.57,573.992,124.912,558.785,124.912z"
                />
                <path
                  fill="#000000"
                  d="M349.318,169.634h142.167c2.54,0,4.582-2.042,4.582-4.574v-9.396v-11.647H344.743v11.647v9.396   C344.743,167.592,346.778,169.634,349.318,169.634z"
                />
                <path
                  fill="#000000"
                  d="M496.067,129.253v-15.11c0-2.54-2.042-4.583-4.582-4.583h-25.329h-11.049v-8.268   c0-15.313-12.412-27.726-27.831-27.726h-13.748c-15.366,0-27.828,12.465-27.828,27.726v8.268h-11.052h-25.33   c-2.54,0-4.575,2.042-4.575,4.583v15.11v12.697h151.324V129.253z M420.393,112.499c-5.565,0-10.072-4.512-10.072-10.083   c0-5.562,4.507-10.068,10.072-10.068c5.571,0,10.078,4.506,10.078,10.068C430.471,107.987,425.964,112.499,420.393,112.499z"
                />
              </g>
            </svg>
            <h1 className="text-black m-0 font-body text-4xl">
              Internet Safety Survey for{' '}
              <span className="text-secondary font-bold">Parents</span>
            </h1>
          </div>

          <ul className="list-disc ml-8">
            <li>
              <span className="text-secondary font-bold">NO</span> personal
              information
            </li>
            <li>
              Takes up to{' '}
              <span className="text-secondary font-bold">5 min</span>
            </li>
            <li>
              <span className="text-secondary font-bold">Surprise</span> reward
              at the end!
            </li>
          </ul>

          <div className="flex-1 flex items-center justify-center">
            <button
              className="block btn btn-secondary mt-2 text-center py-6 w-full text-xl"
              onClick={() =>
                navigate(`/survey-for-parents/?clickid=${clickId}`)
              }
            >
              Start survey
            </button>
          </div>

          <p className="text-sm opacity-75 mb-12">
            The purpose of this survey is to find what bothers you, as parents,
            when it comes to internet safety. This information will be used to
            bring you{' '}
            <span className="font-bold">better educational content</span> in the
            future.
          </p>

          <p className="text-sm opacity-75 mb-8">
            Thank you for answering honestly!
          </p>
        </div>
      </section>
    </Layout>
  );
};

// export const query = graphql`
//   query SurveyForParentsQuery {
//     backgroundImage: file(relativePath: { eq: "lp/pexels-lukas-669616.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1024) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//   }
// `;

export default YSOSurveyForParents;
